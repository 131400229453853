uniform float uTime;
uniform float uDeltaTime;
uniform sampler2D uBase;
uniform float uFlowFieldInfluence;
uniform float uFlowFieldStrength;
uniform float uFlowFieldFrequency;

#include ../includes/simplexNoise4d.glsl

void main() {
    float time = uTime * 0.2;
    // On calcule les coordonnées UV en normalisant les coordonnées de fragment (gl_FragCoord.xy) par la résolution de l'écran (resolution.xy).
    // Cela donne la position du pixel actuel sur notre texture, avec uv allant de (0,0) en bas à gauche à (1,1) en haut à droite.
    vec2 uv = gl_FragCoord.xy / resolution.xy;

    // On échantillonne la texture des particules (uParticles) aux coordonnées UV calculées.
    // On récupère ainsi les informations de la particule correspondant au pixel courant.
    vec4 particle = texture(uParticles, uv);

    // Texture initiale (baseParticlesTexture)
    vec4 base = texture(uBase, uv);

    // Dead
    if(particle.a >= 1.0 ){
        particle.a = mod(particle.a, 1.0);
        particle.xyz = base.xyz;
    }    
    // Alive
    else {
        // Strength
        float strength = simplexNoise4d(vec4(base.xyz * 0.2, time + 1.0));

        // On veut aller de -1 à +1 et non 0 à 1 donc on remap
        float influence = (uFlowFieldInfluence - 0.5) * (- 2.0); 

        strength = smoothstep(influence, 1.0, strength);

        // Flow field
        vec3 flowField = vec3(
            simplexNoise4d(vec4(particle.xyz * uFlowFieldFrequency + 0.0, time)), // x
            simplexNoise4d(vec4(particle.xyz * uFlowFieldFrequency + 1.0, time)), // y
            simplexNoise4d(vec4(particle.xyz * uFlowFieldFrequency + 2.0, time)) // z
        );
        // Comme toujours, on normalise la direction
        flowField = normalize(flowField);
        particle.xyz += flowField * uDeltaTime * strength * uFlowFieldStrength ;

        // Decay
        particle.a += uDeltaTime * 0.3;
    }

    // On assigne la couleur du fragment à la valeur modifiée de la particule.
    // Cela met à jour la texture de sortie avec la nouvelle position de la particule.
    gl_FragColor = particle;

}
