import * as THREE from "three";
import { OrbitControls } from "three/addons/controls/OrbitControls.js";
import { GLTFLoader } from "three/addons/loaders/GLTFLoader.js";
import { DRACOLoader } from "three/addons/loaders/DRACOLoader.js";
import GUI from "lil-gui";
import particlesVertexShader from "./shaders/particles/vertex.glsl";
import particlesFragmentShader from "./shaders/particles/fragment.glsl";
import { GPUComputationRenderer } from "three/addons/misc/GPUComputationRenderer.js";
import gpgpuParticlesShader from "./shaders/gpgpu/particles.glsl";
import { gsap } from "gsap";

/**
 * Base
 */
// Debug
// const gui = new GUI({ width: 340 });
const debugObject = {};

// Canvas
const canvas = document.querySelector("canvas.webgl");

// Scene
const scene = new THREE.Scene();

// Loaders
const loadingBarElement = document.querySelector(".loading-bar");
const loadingManager = new THREE.LoadingManager(
  // Loaded
  () => {
    // Wait a little
    window.setTimeout(() => {
      // Animate overlay
      gsap.to(overlayMaterial.uniforms.uAlpha, {
        duration: 3,
        value: 0,
        delay: 1,
      });

      // Update loadingBarElement
      loadingBarElement.classList.add("ended");
      loadingBarElement.style.transform = "";
    }, 500);
  },

  // Progress
  (itemUrl, itemsLoaded, itemsTotal) => {
    // Calculate the progress and update the loadingBarElement
    const progressRatio = itemsLoaded / itemsTotal;
    loadingBarElement.style.transform = `scaleX(${progressRatio})`;
  }
);

const dracoLoader = new DRACOLoader(loadingManager);
dracoLoader.setDecoderPath("/draco/");

const gltfLoader = new GLTFLoader(loadingManager);
gltfLoader.setDRACOLoader(dracoLoader);

/**
 * Sizes
 */
const sizes = {
  width: window.innerWidth,
  height: window.innerHeight,
  pixelRatio: Math.min(window.devicePixelRatio, 2),
};

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(
  35,
  sizes.width / sizes.height,
  0.1,
  100
);
camera.position.set(0, 0.5, 11);
scene.add(camera);

// Todo -> enabled quand clique sur le canvas une première fois (ajouter un style de pointer), faire un effet de zoom
// + ajouter un bouton + sur appui key "echap" pour quitter + effet de dezoom et passage à false
// Controls
const controls = new OrbitControls(camera, canvas);
controls.enableDamping = true;

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
  canvas: canvas,
  antialias: true,
});
renderer.setSize(sizes.width, sizes.height);
renderer.setPixelRatio(sizes.pixelRatio);

debugObject.clearColor = "#212121";
renderer.setClearColor(debugObject.clearColor);

/**
 * Load model
 */
let baseGeometry = {};

gltfLoader.load("./bust_v4.glb", (gltf) => {
  baseGeometry.instance = gltf.scene.children[0].geometry;
  baseGeometry.count = baseGeometry.instance.attributes.position.count;
  initParticles();
});

/**
 * Overlay
 */
const overlayGeometry = new THREE.PlaneGeometry(2, 2, 1, 1);
const overlayMaterial = new THREE.ShaderMaterial({
  // wireframe: true,
  transparent: true,
  uniforms: {
    uAlpha: { value: 1 },
  },
  vertexShader: `
        void main()
        {
            gl_Position = vec4(position, 1.0);
        }
    `,
  fragmentShader: `
        uniform float uAlpha;

        void main()
        {
            gl_FragColor = vec4(0.0, 0.0, 0.0, uAlpha);
        }
    `,
});
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial);
scene.add(overlay);

/**
 * Base geometry
 */
const initParticles = () => {
  window.addEventListener("resize", () => {
    // Update sizes
    sizes.width = window.innerWidth;
    sizes.height = window.innerHeight;
    sizes.pixelRatio = Math.min(window.devicePixelRatio, 2);

    // Materials
    particles.material.uniforms.uResolution.value.set(
      sizes.width * sizes.pixelRatio,
      sizes.height * sizes.pixelRatio
    );

    // Update camera
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(sizes.pixelRatio);
  });

  /**
   * GPU Compute
   */
  // Setup
  const gpgpu = {};
  gpgpu.size = Math.ceil(Math.sqrt(baseGeometry.count));
  gpgpu.computation = new GPUComputationRenderer(
    gpgpu.size,
    gpgpu.size,
    renderer
  );

  // Base particles
  const baseParticlesTexture = gpgpu.computation.createTexture();

  for (let i = 0; i < baseGeometry.count; i++) {
    const i3 = i * 3;
    const i4 = i * 4;

    // Position based on geometry
    baseParticlesTexture.image.data[i4 + 0] =
      baseGeometry.instance.attributes.position.array[i3 + 0];
    baseParticlesTexture.image.data[i4 + 1] =
      baseGeometry.instance.attributes.position.array[i3 + 1];
    baseParticlesTexture.image.data[i4 + 2] =
      baseGeometry.instance.attributes.position.array[i3 + 2];
    baseParticlesTexture.image.data[i4 + 3] = Math.random();
  }

  // Particles variable
  gpgpu.particlesVariable = gpgpu.computation.addVariable(
    "uParticles",
    gpgpuParticlesShader,
    baseParticlesTexture
  );
  gpgpu.computation.setVariableDependencies(gpgpu.particlesVariable, [
    gpgpu.particlesVariable,
  ]);

  // Uniforms
  gpgpu.particlesVariable.material.uniforms.uTime = new THREE.Uniform(0);

  gpgpu.particlesVariable.material.uniforms.uBase = new THREE.Uniform(
    baseParticlesTexture
  );

  gpgpu.particlesVariable.material.uniforms.uDeltaTime = new THREE.Uniform(0);

  gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence =
    new THREE.Uniform(0.5);

  gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength =
    new THREE.Uniform(2);

  gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency =
    new THREE.Uniform(0.5);

  // Init
  gpgpu.computation.init();

  // Debug
  gpgpu.debug = new THREE.Mesh(
    new THREE.PlaneGeometry(3, 3),
    new THREE.MeshBasicMaterial({
      map: gpgpu.computation.getCurrentRenderTarget(gpgpu.particlesVariable)
        .texture,
      // transparent: true,
    })
  );
  gpgpu.debug.visible = false;
  gpgpu.debug.position.x = 3;
  scene.add(gpgpu.debug);

  // gui.add(gpgpu.debug, "visible");

  // gui.show(false);

  /**
   * Particles
   */
  const particles = {};

  // Geometry
  const sizesArray = new Float32Array(baseGeometry.count);

  // Nous devons donc créer manuellement un tableau de coordonnées UV (particlesUvArray) pour indiquer à chaque particule quel pixel de la texture (uParticlesTexture) elle doit utiliser.
  const particlesUvArray = new Float32Array(baseGeometry.count * 2);

  // Boucle sur l'axe y (lignes)
  for (let y = 0; y < gpgpu.size; y++) {
    // Boucle sur l'axe x (colonnes)
    for (let x = 0; x < gpgpu.size; x++) {
      // particleIndex est l'index de la particule traitée
      const particleIndex = y * gpgpu.size + x;
      // uvIndex est l'index dans le tableau unidimensionnel particlesUvArray
      // Chaque particule a deux valeurs UV (u, v), donc on multiplie l'index par 2
      const uvIndex = particleIndex * 2;

      // Particles UV
      const uvX = (x + 0.5) / gpgpu.size;
      const uvY = (y + 0.5) / gpgpu.size;

      // Assignation des coordonnées UV à chaque particule
      particlesUvArray[uvIndex + 0] = uvX;
      particlesUvArray[uvIndex + 1] = uvY;

      // Size
      sizesArray[particleIndex] = Math.random();
    }
  }

  particles.geometry = new THREE.BufferGeometry();
  particles.geometry.setDrawRange(0, baseGeometry.count);
  particles.geometry.setAttribute(
    "aParticleUv",
    new THREE.BufferAttribute(particlesUvArray, 2)
  );
  particles.geometry.setAttribute(
    "aColor",
    baseGeometry.instance.attributes.color
  );
  particles.geometry.setAttribute(
    "aSize",
    new THREE.BufferAttribute(sizesArray, 1)
  );

  // Material
  particles.material = new THREE.ShaderMaterial({
    vertexShader: particlesVertexShader,
    fragmentShader: particlesFragmentShader,
    uniforms: {
      uSize: new THREE.Uniform(0.07),
      uResolution: new THREE.Uniform(
        new THREE.Vector2(
          sizes.width * sizes.pixelRatio,
          sizes.height * sizes.pixelRatio
        )
      ),
      uParticlesTexture: new THREE.Uniform(),
    },
    side: THREE.DoubleSide,
  });

  // Points
  particles.points = new THREE.Points(particles.geometry, particles.material);

  particles.points.rotateY(Math.PI);
  particles.points.position.y = -1.5;

  scene.add(particles.points);

  // Avoid Frustrum Culling bug
  window.requestAnimationFrame(() => {
    particles.points.geometry.boundingSphere.set(0, 1);
  });

  /**
   * Tweaks
   */
  // gui.addColor(debugObject, "clearColor").onChange(() => {
  //   renderer.setClearColor(debugObject.clearColor);
  // });
  // gui
  //   .add(particles.material.uniforms.uSize, "value")
  //   .min(0)
  //   .max(1)
  //   .step(0.001)
  //   .name("uSize");

  // gui
  //   .add(gpgpu.particlesVariable.material.uniforms.uFlowFieldInfluence, "value")
  //   .min(0)
  //   .max(1)
  //   .name("uFlowFieldInfluence");

  // gui
  //   .add(gpgpu.particlesVariable.material.uniforms.uFlowFieldStrength, "value")
  //   .min(0)
  //   .max(10)
  //   .name("uFlowFieldStrength");

  // gui
  //   .add(gpgpu.particlesVariable.material.uniforms.uFlowFieldFrequency, "value")
  //   .min(0)
  //   .max(1)
  //   .step(0.001)
  //   .name("uFlowFieldFrequency");

  /**
   * Animate
   */
  const clock = new THREE.Clock();
  let previousTime = 0;

  const tick = () => {
    const elapsedTime = clock.getElapsedTime();
    const deltaTime = elapsedTime - previousTime;
    previousTime = elapsedTime;

    // Update controls
    controls.update();

    // GPGPU Update
    gpgpu.particlesVariable.material.uniforms.uTime.value = elapsedTime;

    gpgpu.particlesVariable.material.uniforms.uDeltaTime.value = deltaTime;

    gpgpu.computation.compute();

    // On récupère ici le dernier Frame Buffer Object (FBO) généré par le gpgpu.computation après le processus de ping-pong du compute shader, et on le passe à l'uniforme uParticlesTexture du matériau des particules. Cela permet d'utiliser les positions calculées des particules dans le shader de rendu.
    particles.material.uniforms.uParticlesTexture.value =
      gpgpu.computation.getCurrentRenderTarget(gpgpu.particlesVariable).texture;

    // Render normal scene
    renderer.render(scene, camera);

    // Call tick again on the next frame
    window.requestAnimationFrame(tick);
  };

  tick();
};
